<template>
  <Modal :value="visible"
         :title="title"
         width="540"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont"
         v-if="item">
      <div class="status-title">
        <p class="li-title">{{item.faclname}}</p>
        <span>{{item.facltypeitemname}}</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    status: String,
    item: null
  },
  data () {
    return {
      remark: ''
    }
  },
  computed: {
    title () {
      if (this.item && this.status) {
        let statusStr
        switch (this.status) {
          case '0':
            statusStr = '恢复'
            break;
          case '1':
            statusStr = '废弃'
            break;
          case '2':
            statusStr = '维修'
            break;
          case '3':
            statusStr = '拆除'
            break;
          case '4':
            statusStr = '暂停'
            break;
          default:
            break;
        }
        return this.item.facltypename + statusStr
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 点击确认
    onClickConfirm () {
      let params = {
        ids: this.item.id,
        status: this.status,
        remark: this.remark,
        // 没用的数据，根据接口要求必须返回
        name: this.item.faclname,
        bigType: this.item.facltypeid,
        smallType: this.item.facltypeitemid,
        number: this.item.faclcode,
        departId: this.item.facldeptid,
        principalId: this.item.principal,
        maintainId: this.item.mtadeptId,
        radius: this.item.radii,
        date: this.item.startdate,
        business: this.item.businessarea,
        lnglat: this.item.facladdr,
        site: this.item.facladdrdetail,
        picture: this.item.fileurl,
        others: []
      }
      this.loading = true
      this.$store.dispatch('createOrEditFacility', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:item', null)
      }
    },
    resetData () {
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.status-title {
  font-size: 14px;
  color: rgb(51, 51, 51);
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  p {
    font-weight: bold;
    margin-right: 8px;
  }
  span {
    font-size: 12px;
    color: rgb(153, 153, 153);
    font-weight: normal;
  }
}
</style>
